import React, { useRef, useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { MdPauseCircleFilled, MdPlayCircleFilled } from 'react-icons/md';

interface WaveSurferPlayerProps {
  audioUrl: string;
}

const WaveSurferPlayer: React.FC<WaveSurferPlayerProps> = ({ audioUrl }) => {
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string>('0:00');
  const [duration, setDuration] = useState<string>('0:00');

  useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: 'skyblue',
        progressColor: 'steelblue',
        cursorColor: 'navy',
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 1,
        height: 75,
        barGap: 1,
      });

      wavesurfer.current.load(audioUrl);

      wavesurfer.current.on('play', () => setIsPlaying(true));
      wavesurfer.current.on('pause', () => setIsPlaying(false));
      wavesurfer.current.on('ready', () => {
        if (wavesurfer.current) {
          setDuration(formatTime(wavesurfer.current.getDuration()));
        }
      });
      wavesurfer.current.on('audioprocess', () => {
        if (wavesurfer.current) {
          setCurrentTime(formatTime(wavesurfer.current.getCurrentTime()));
        }
      });
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
    }
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex flex-col items-center justify-between w-full">
      <div className="flex flex-row items-center justify-center w-full mx-auto">
        <div onClick={handlePlayPause} className="cursor-pointer">
          {isPlaying ? <MdPauseCircleFilled size={24} /> : <MdPlayCircleFilled size={24} />}
        </div>
        <div ref={waveformRef} className="w-full" />
      </div>
      <div className="flex justify-between w-full mt-2">
        <span>{currentTime}</span>
        <span>{duration}</span>
      </div>
    </div>
  );
};

export default WaveSurferPlayer;
