import { AppStoreButton } from 'react-mobile-app-button';
import CompactPlayer from './CompactPlayer';

export default function Standalone() {
  return (
    <>
      <CompactPlayer />
      <AppStoreButton
        className="mt-5 cursor-default pointer-events-none"
        url="#"
        title="Coming Soon To"
        theme="dark"
        height={60}
      />
    </>
  );
}
