import React from 'react';
import { Route, Switch } from 'wouter';
import AudioForm from './components/AudioForm';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Hero from './components/Hero';
import Standalone from './components/Standalone';
import EmbeddedPlayer from './components/EmbeddedPlayer';
import Privacy from './components/Privacy';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="w-screen h-screen">
        <div className="pt-5 flex flex-col items-center">
          <Switch>
            <Route path="/embed/:id" component={EmbeddedPlayer} />
            <Route path="/listen/:id" component={Standalone} />
            <Route path="/create" component={AudioForm} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/" component={Hero} />
            <Route>404: No such page!</Route>
          </Switch>
        </div>
      </div>
    </QueryClientProvider>
  );
}

export default App;
