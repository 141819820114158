import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { v4 as uuidv4 } from 'uuid';
import './segmenter.css';

export interface SentenceSegmenterOptions {
  maxWords: number;
  minWordsForSplit: number;
  maxSentenceLength: number;
  onInsertSegment: (id: string | null, text: string | null) => void;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    sentenceSegmenter: {
      segmentSentences: () => ReturnType;
    };
  }
}

export const SentenceSegmenter = Extension.create<SentenceSegmenterOptions>({
  name: 'sentenceSegmenter',

  addOptions() {
    return {
      maxWords: 50,
      minWordsForSplit: 15,
      maxSentenceLength: 200,
      onInsertSegment: () => {},
    };
  },

  addCommands() {
    return {
      segmentSentences:
        () =>
        ({ state, tr, dispatch, editor }) => {
          let segments: { from: number; to: number }[] = [];

          state.doc.descendants((node: ProseMirrorNode, pos: number) => {
            if (node.isText) {
              const text = node.text || '';
              let lastBreak = 0;
              let wordCount = 0;
              let segmentStart = pos;

              const words = text.split(/\s+/);
              words.forEach((word, index) => {
                wordCount++;

                const isNaturalBreak = /[.!?]$/.test(word);
                const isLastWord = index === words.length - 1;
                const wordEndPos = pos + lastBreak + word.length;

                if (isNaturalBreak || wordCount >= this.options.maxWords || isLastWord) {
                  segments.push({
                    from: segmentStart,
                    to: wordEndPos,
                  });

                  segmentStart = wordEndPos + 1; // +1 to account for the space
                  wordCount = 0;
                }

                lastBreak += word.length + 1; // +1 for the space
              });
            }
          });

          if (dispatch) {
            let inserted = 0;
            segments.forEach(({ from, to }, index) => {
              const commentId = uuidv4();
              const slice = editor.state.doc.slice(from, to);
              const contentText = slice.content.textBetween(0, slice.content.size, ' ');
              const pauseNode = editor.schema.nodes.pauseValue.create();
              if (index !== 0) {
                tr.insert(from + inserted, pauseNode);
                inserted += 1;
              }
              tr.addMark(from + inserted, to + inserted, editor.schema.marks.comment.create({ commentId }));
              this.options.onInsertSegment(commentId, contentText);
            });
          }

          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    const pluginKey = new PluginKey('sentenceSegmenterButton');

    return [
      new Plugin({
        key: pluginKey,
        view: (editorView: EditorView) => {
          const button = document.createElement('button');
          button.setAttribute('class', 'segmenter');
          button.textContent = 'Segment Sentences';
          button.addEventListener('click', () => {
            this.editor.commands.segmentSentences();
          });

          editorView.dom.parentElement?.appendChild(button);

          return {
            destroy() {
              button.remove();
            },
          };
        },
      }),
    ];
  },
});
