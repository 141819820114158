import React, { useState } from 'react';
import useVoiceStore, { Voiceline } from '../hooks/useVoiceStore';
import { useQuery } from '@tanstack/react-query';

function Snippet({ voiceline, speaker }: { voiceline: Voiceline; speaker: string }) {
  const updateVoice = useVoiceStore(state => state.updateVoice);

  const { isLoading } = useQuery({
    queryKey: ['voiceline', { id: voiceline.id }],
    queryFn: async () => {
      const response = await fetch('http://192.168.0.61:8020/tts_to_audio/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: voiceline.text,
          speaker_wav: speaker,
          language: 'en',
        }),
      });

      if (response.ok) {
        const audioBlob = await response.blob();
        updateVoice(voiceline.id, audioBlob);
        return URL.createObjectURL(audioBlob);
      } else {
        console.error(`Server error on request`, response.statusText);
        return null;
      }
    },
    staleTime: 365 * 24 * 60 * 60 * 1000,
    gcTime: 365 * 24 * 60 * 60 * 1000,
    refetchOnMount: false,
  });

  return isLoading ? (
    <span className="m-1 w-[10px] h-[10px] bg-red-500"></span>
  ) : (
    <span className="m-1 w-[10px] h-[10px] bg-green-500"></span>
  );
}

export default function VoiceBox() {
  const voicelines = useVoiceStore(state => state.voicelines);
  const [selectedValue, setSelectedValue] = useState<string>('mccarron');

  const { isLoading: speakersLoading, data: speakers } = useQuery({
    queryKey: ['speakers'],
    queryFn: async () => {
      const response = await fetch('http://192.168.0.61:8020/speakers_list/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const speakers = await response.json();
        return speakers;
      } else {
        return [];
      }
    },
    staleTime: 365 * 24 * 60 * 60 * 1000,
    gcTime: 365 * 24 * 60 * 60 * 1000,
    refetchOnMount: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
  };

  return (
    <div className="w-96 mt-[150px] flex flex-col gap-5 max-h-screen pb-[100vh]">
      {/* <input
        className="text-sm mx-1 border border-gray-200 rounded-md p-1"
        defaultValue={speaker}
        onChange={e => setSpeaker(e.target.value)}
      /> */}
      {!speakersLoading && (
        <select
          value={selectedValue}
          onChange={handleChange}
          className="block fixed w-96 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          {speakers.map((option: string) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      )}
      <div className="flex flex-row flex-wrap fixed w-96 mt-10">
        {voicelines.map(v => (
          <Snippet voiceline={v} speaker={selectedValue} />
        ))}
      </div>
    </div>
  );
}
