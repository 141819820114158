import { useCurrentEditor } from '@tiptap/react';
import React, { useRef, useState, useEffect } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { MdDownloadForOffline } from 'react-icons/md';
import useVoiceStore from '../hooks/useVoiceStore';
import { combineWavBlobs, generateSilentAudioBlob } from '../lib/utils';

// React component for the audio player
export default function VoiceJoiner() {
  const { editor } = useCurrentEditor();
  const audioPlayerEl = useRef<AudioPlayer | null>(null);
  const [audioUrl, setAudioUrl] = useState('');
  const [intervalAudioUrl, setintervalAudioUrl] = useState('');
  const [pbRate, setPbRate] = useState(1);
  const voicelines = useVoiceStore(state => state.voicelines);
  const intervalAudio = useVoiceStore(state => state.intervalAudio);

  // For playing the interval audio as and when required
  useEffect(() => {
    if (intervalAudio) setintervalAudioUrl(URL.createObjectURL(intervalAudio));
  }, [intervalAudio]);

  async function loadEditorAudio() {
    if (!editor) return;
    let idx = 0;
    const { state } = editor;
    const audioBlobs = new Array<any>();
    const promises: any[] = [];

    state.doc.nodesBetween(0, state.doc.content.size, node => {
      if (node.type.name === 'pauseValue' && node.attrs.value !== 0) {
        // Generate blob of appropraite length and add
        promises.push(
          generateSilentAudioBlob(idx, node.attrs.value).then(({ index, blob }) => {
            audioBlobs.push({ idx: index, blob });
          }),
        );
        idx += 1;
        return;
      }

      node.marks.forEach(mark => {
        if (mark.type.name === 'comment' && mark.attrs.commentId) {
          // Grab audio blob from voice store and push to array
          const blob = voicelines.find(v => v.id === mark.attrs.commentId)?.audio;
          if (blob) audioBlobs.push({ idx, blob });
          idx += 1;
        }
      });
    });

    await Promise.all(promises);
    const sortedBlobsArray = audioBlobs.sort((a, b) => a.idx - b.idx);
    const audioBlobParts: Blob[] = sortedBlobsArray.map(item => item.blob);
    const combinedBlob = await combineWavBlobs(audioBlobParts);
    setAudioUrl(URL.createObjectURL(combinedBlob));
  }

  return (
    <div className="audio-player w-96 absolute top-0 right-0 h-[150px] overflow-hidden">
      <div className="fixed w-96">
        <button
          onClick={loadEditorAudio}
          className="w-full mb-10 border border-blue-500 bg-blue-50 px-2 py-1 rounded-md hover:bg-blue-200 cursor-pointer"
        >
          Load Editor Audio
        </button>
        <AudioPlayer
          showSkipControls
          src={audioUrl}
          ref={audioPlayerEl}
          customProgressBarSection={[RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION]}
          customVolumeControls={[
            <MdDownloadForOffline
              onClick={() => {
                const a = document.createElement('a');
                a.href = audioUrl;
                a.download = `document.wav`;
                // Programmatically trigger the click event
                document.body.appendChild(a); // Append the link to the document body
                a.click();
                document.body.removeChild(a); // Remove the link after clicking
              }}
              className="cursor-pointer text-gray-500 hover:text-gray-700 w-6 h-6"
            />,
          ]}
          // onEnded={() => nextTrack()}
          // onClickNext={() => nextTrack()}
          // onClickPrevious={() => prevTrack()}
          customControlsSection={[
            <div
              onClick={() => {
                if (!audioPlayerEl.current?.audio.current?.playbackRate) return;
                let rate = audioPlayerEl.current.audio.current.playbackRate;
                rate = rate === 1 ? 1.5 : rate === 1.5 ? 2 : 1;
                audioPlayerEl.current.audio.current.playbackRate = rate;
                setPbRate(rate);
              }}
              className="w-[50px] text-sm text-gray-500 cursor-pointer"
            >
              {pbRate}x
            </div>,
            RHAP_UI.MAIN_CONTROLS,
            RHAP_UI.VOLUME_CONTROLS,
          ]}
        />
        <AudioPlayer autoPlay src={intervalAudioUrl} className="scale-[0]" />
      </div>
    </div>
  );
}
