import { useQuery } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';
import supabase from '../lib/supabase';
import useTagStore from '../hooks/use-tag-store';
import VideoPlayer from './VideoPlayer';
import { useRoute } from 'wouter';

const CompactPlayer = () => {
  const [, params] = useRoute<{ id: string }>('/listen/:id');
  const tags = useTagStore(state => state.tags);
  const initialTrack = params?.id ?? null;
  const [currentTrack, setCurrentTrack] = useState(0);
  const [progressPct, setProgressPct] = useState<any>(0);

  // const [pbRate, setPbRate] = useState(1);

  const nextTrack = () => {
    setCurrentTrack(currentTrack + 1);
  };

  const prevTrack = () => {
    setCurrentTrack(currentTrack > 0 ? currentTrack - 1 : currentTrack);
  };

  // @ts-ignore
  const { isLoading, data: post } = useQuery({
    queryKey: ['playlist', { track: currentTrack }],
    queryFn: async () => {
      // If the currentTrack is 0 and we do have a hash location, get that particular track
      const result =
        currentTrack === 0 && initialTrack && initialTrack.length
          ? await supabase.from('uploads').select('*, profiles (*)').eq('file', initialTrack).single()
          : await supabase
              .rpc('select_random_uploads_with_tags', {
                tag_list: tags,
                seed: Math.random(),
                limit_val: 1,
                offset_val: 0,
              })
              .single();
      // if(result.data) shuffleArray(result.data);
      console.log(result);
      return result as any;
    },
    staleTime: 365 * 24 * 60 * 60 * 1000,
    gcTime: 365 * 24 * 60 * 60 * 1000,
    refetchOnMount: false,
  });

  const { isLoading: isWaveformLoading, data: waveform } = useQuery({
    queryKey: ['waveform', { track: currentTrack }],
    queryFn: async () => {
      // @ts-ignore
      const response = await fetch(
        `https://fm-jacket-uploads.s3.us-west-2.amazonaws.com/${post.data.profile_id}/${post.data.file}.svg`,
      );
      if (!response.ok) {
        throw new Error('Error fetching waveform');
      }
      const result = await response.text();
      if (result.charAt(0) === 'I') throw new Error('Error fetching waveform');
      return result;
    },
    staleTime: 365 * 24 * 60 * 60 * 1000,
    gcTime: 365 * 24 * 60 * 60 * 1000,
    refetchOnMount: false,
    enabled: !!post,
  });

  const containerEl = useRef<HTMLDivElement | null>(null);

  return (
    <div className="flex flex-col w-full items-center justify-center p-4">
      <div
        ref={containerEl}
        className="rounded-2xl max-w-[390px] overflow-hidden border-card-border shadow-lg border-b-[4px] bg-card-background"
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col pb-2 min-h-[186px]">
            {isLoading && <span className="p-2">Your audio feed is loading...</span>}
            {!isLoading && post?.data === null && (
              <span className="p-2 px-10">Nothing to load, the database is empty.</span>
            )}
            {!isLoading && post?.data && (
              <div className="flex flex-row mb-5 px-2 pt-2">
                <img
                  alt="User avatar"
                  src={post.data.profiles?.avatar ?? post.data.avatar}
                  className="w-[50px] h-[50px] rounded-full"
                />
                <div className="flex flex-col mx-2 flex-1 min-w-[0]">
                  <span className="text-card-subtext truncate">
                    @{post.data.profiles?.username ?? post.data.username}
                  </span>
                  <span className="font-semibold text-[#004080] truncate">{post.data.title}</span>
                  {/* <span className='text-xs text-gray-500'>{format(post.created, 'MMM dd, HH:mm')}</span> */}
                </div>
                {/* <audio src={`https://hkucorcqtbifnlqafxir.supabase.co/storage/v1/object/public/files/${post.profile_id}/${post.file}`} controls /> */}
                {/* <p className='text-sm'>{post.description}</p> */}
              </div>
            )}
            {!isWaveformLoading && (
              <div className="w-[390px] h-[100px] relative">
                <div
                  className="absolute top-0"
                  dangerouslySetInnerHTML={{
                    __html: waveform ? waveform.replace(/stroke="[^"]*"/g, 'stroke="#999999"') : '' || '',
                  }}
                />
                <div
                  className="absolute top-0 border-r-2 border-[#004080] overflow-hidden"
                  style={{ width: `${progressPct}%` }}
                  dangerouslySetInnerHTML={{
                    __html: waveform ? waveform.replace(/stroke="[^"]*"/g, 'stroke="#004080"') : '',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {!isLoading && post?.data && (
        // @ts-ignore
        <VideoPlayer
          setProgressPct={setProgressPct}
          src={`https://fm-jacket-uploads.s3.us-west-2.amazonaws.com/${post.data.profile_id}/${post.data.file}.m3u8`}
          playNext={nextTrack}
          playPrev={prevTrack}
        />
      )}
    </div>
  );
};

export default CompactPlayer;
