import { create } from 'zustand';

export type Tag = 'originals' | 'business' | 'horror' | 'stories' | 'poetry';

interface TagStore {
  tags: Tag[];
  appendTag: (tag: Tag) => void;
  removeTag: (tag: Tag) => void;
}

const useTagStore = create<TagStore>(set => ({
  tags: ['business', 'poetry', 'stories', 'originals'],
  appendTag: tag => set(state => ({ tags: [...state.tags, tag] })),
  removeTag: tag => set(state => ({ tags: state.tags.filter(t => t !== tag) })),
}));

export default useTagStore;
