import { Node, mergeAttributes } from '@tiptap/core';
import { TextSelection } from 'prosemirror-state';
import './pause.css';

function formatToThreeDigits(value: number): string {
  return value.toFixed(2).padStart(2, '0');
}

interface PauseValueOptions {
  values: number[];
  defaultValue: number;
  makeIntervalAudio: (one: string, two: string, duration: number) => void;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    pauseValue: {
      setPauseValue: (attributes: { value: number }) => ReturnType;
    };
  }
}

export const PauseValue = Node.create<PauseValueOptions>({
  name: 'pauseValue',

  inline: true,
  group: 'inline',
  atom: true,

  addOptions() {
    return {
      values: [0, 0.25, 0.5, 0.75, 1.0, 1.5, 1.75],
      defaultValue: 0.5,
      makeIntervalAudio: () => {},
    };
  },

  addAttributes() {
    return {
      value: {
        default: this.options.defaultValue,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[pause-value]',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes, { 'pause-value': node.attrs.value }), node.attrs.value.toString()];
  },

  addNodeView() {
    return ({ node, getPos, editor }) => {
      // @ts-ignore
      const pos = getPos();
      const select = document.createElement('select');
      select.setAttribute('class', 'pause-value');

      // Create options for the dropdown
      this.options.values.forEach(value => {
        const option = document.createElement('option');
        option.value = value.toString();
        option.text = formatToThreeDigits(value);
        if (value === node.attrs.value) {
          option.selected = true;
        }
        select.appendChild(option);
      });

      // Handle the change event to update the node's value
      select.addEventListener('change', event => {
        const newValue = Number((event.target as HTMLSelectElement).value);
        // @ts-ignore
        const transaction = editor.state.tr.setNodeMarkup(getPos(), undefined, {
          value: newValue,
        });
        editor.view.dispatch(transaction);

        // Fetch the two nodes on the left and right, and if they have mark type
        // name 'comment', get the commentId attr
        const markBefore = editor.state.doc
          .nodeAt(pos - 2)
          ?.marks.find(mark => mark.type === editor.schema.marks.comment);
        const markAfter = editor.state.doc
          .nodeAt(pos + 2)
          ?.marks.find(mark => mark.type === editor.schema.marks.comment);

        if (markBefore && markAfter) {
          this.options.makeIntervalAudio(markBefore.attrs.commentId, markAfter.attrs.commentId, newValue);
        }
      });

      return {
        dom: select,
      };
    };
  },

  addCommands() {
    return {
      setPauseValue:
        attributes =>
        ({ tr, dispatch, state }) => {
          const nodeType = this.type;
          const { from, to } = state.selection;

          if (dispatch) {
            const node = nodeType.create(attributes);
            const transaction = tr.insert(from, node);

            // Set the selection after the inserted node
            tr.setSelection(TextSelection.create(tr.doc, to + node.nodeSize));

            dispatch(transaction);
          }

          return true;
        },
    };
  },
});
