import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import ControlBar from './ControlBar';

const VideoPlayer = ({
  src,
  setProgressPct,
  playNext,
  playPrev,
}: {
  src: string;
  setProgressPct: (pctProgress: number) => void;
  playNext: () => void;
  playPrev: () => void;
}) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const rafRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (!playerRef.current) return;
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleForward = () => {
    if (!playerRef.current) return;
    playerRef.current.currentTime += 15;
  };

  const handleRewind = () => {
    if (!playerRef.current) return;
    playerRef.current.currentTime -= 15;
  };

  const handleProgress = useCallback(() => {
    if (playerRef.current) {
      const duration = playerRef.current.duration;
      const currentTime = playerRef.current.currentTime;
      const progressPercentage = (currentTime / duration) * 100;
      setProgressPct(progressPercentage);
    }
    rafRef.current = requestAnimationFrame(handleProgress);
  }, [setProgressPct]);

  const handlePlay = () => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
    rafRef.current = requestAnimationFrame(handleProgress);
  };

  const handlePause = () => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
  };

  useEffect(() => {
    if (playerRef.current) {
      try {
        playerRef.current.play();
      } catch (e) {
        // Probably no permission
      }
    }
  }, [src]);

  useEffect(() => {
    rafRef.current = requestAnimationFrame(handleProgress);
    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [handleProgress]);

  return (
    <div>
      <ControlBar
        isPlaying={isPlaying}
        onPlayPause={handlePlayPause}
        onForward={handleForward}
        onRewind={handleRewind}
        onNext={playNext}
        onPrevious={playPrev}
      />
      <ReactHlsPlayer
        playerRef={playerRef}
        onPlay={handlePlay}
        onPause={handlePause}
        src={src}
        autoPlay={false}
        controls={false}
        width="100%"
        height="0"
        className="hidden"
        onProgress={handleProgress}
      />
    </div>
  );
};

export default VideoPlayer;
