import { AppStoreButton } from 'react-mobile-app-button';
import CompactPlayer from './CompactPlayer';

export default function Hero() {
  return (
    <div className="py-12 md:py-24">
      <div className="max-w-screen-xl mx-auto px-6 lg:px-8 xl:px-4 grid md:grid-cols-4 xl:grid-cols-5 gap-x-12 lg:gap-x-20">
        <div className="order-2 md:order-1 col-span-2 self-center mt-12 md:mt-0">
          <h1 className="text-gray-100 text-3xl md:text-4xl lg:text-5xl font-bold mb-2 md:mb-4 lg:mb-6">Jacket.fm</h1>
          <h2 className="text-gray-200 text-xl md:text-2xl lg:text-3xl font-bold mb-1 md:mb-2 lg:mb-4">
            Discover your next audio obsession.
          </h2>
          <p className="text-lg xl:text-xl text-gray-100 mb-6 lg:mb-8 xl:mb-10">
            Treat your ears to short-form audio selected just for you, or upload and share your voice with the world!
          </p>
          {/* <div className="flex space-x-4 mb-6">
            <input type="text" placeholder="enter your email..." className="flex-1 py-4 px-4 border border-gray-200 rounded-lg leading-none focus:outline-none" />
            <button className="focus:outline-none inline-block bg-gradient-to-br from-indigo-600 to-indigo-700 hover:from-indigo-500 hover:to-indigo-700 font-semibold rounded-lg py-2 px-5  text-white ">Get started</button>
          </div>
          <p className="text-gray-500 text-sm">No credit card required. Cancel anytime.</p> */}
          {/* <Choice /> */}
          <AppStoreButton
            className="mt-5 cursor-default pointer-events-none"
            url="#"
            title="Coming Soon To"
            theme="dark"
            height={60}
          />
        </div>
        <div className="order-1 md:order-2 col-span-2 xl:col-span-3">
          <CompactPlayer />
        </div>
      </div>
    </div>
  );
}
