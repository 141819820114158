// Adapted from https://github.com/sereneinserenade/tiptap-comment-extension
import { Mark, mergeAttributes, Range } from '@tiptap/core';
import { Mark as PMMark } from '@tiptap/pm/model';
import './voiceline.css';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    comment: {
      /**
       * Set a comment (add)
       */
      setVoiceline: (commentId: string) => ReturnType;
      /**
       * Unset a comment (remove)
       */
      unsetVoiceline: (commentId: string) => ReturnType;
    };
  }
}

export interface MarkWithRange {
  mark: PMMark;
  range: Range;
}

export interface CommentOptions {
  HTMLAttributes: Record<string, any>;
  onCommentActivated: (commentId: string | null) => void;
}

export interface CommentStorage {
  activeCommentId: string | null;
}

export const VoicelineExtension = Mark.create<CommentOptions, CommentStorage>({
  name: 'comment',

  addOptions() {
    return {
      HTMLAttributes: {},
      onCommentActivated: () => {},
    };
  },

  addAttributes() {
    return {
      highlighted: {
        default: false,
        parseHTML: el => (el as HTMLSpanElement).getAttribute('data-highlighted'),
        renderHTML: attrs => ({ 'data-highlighted': attrs.highlighted }),
      },
      commentId: {
        default: null,
        parseHTML: el => (el as HTMLSpanElement).getAttribute('data-comment-id'),
        renderHTML: attrs => ({ 'data-comment-id': attrs.commentId }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-comment-id]',
        getAttrs: el => !!(el as HTMLSpanElement).getAttribute('data-comment-id')?.trim() && null,
      },
      {
        tag: 'span[data-comment-id]',
        getAttrs: el => !!(el as HTMLSpanElement).getAttribute('data-highlighted')?.trim() && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { class: 'voiceline' }), 0];
  },

  onSelectionUpdate() {
    const { $from } = this.editor.state.selection;

    const marks = $from.marks();

    if (!marks.length) {
      this.storage.activeCommentId = null;
      this.options.onCommentActivated(this.storage.activeCommentId);
      return;
    }

    const commentMark = this.editor.schema.marks.comment;

    const activeCommentMark = marks.find(mark => mark.type === commentMark);

    this.storage.activeCommentId = activeCommentMark?.attrs.commentId || null;

    this.options.onCommentActivated(this.storage.activeCommentId);
  },

  addStorage() {
    return {
      activeCommentId: null,
    };
  },

  // @ts-ignore
  addCommands() {
    return {
      setVoiceline:
        commentId =>
        ({ commands }) => {
          if (!commentId) return false;

          commands.setMark('comment', { commentId });
        },
      unsetVoiceline:
        commentId =>
        ({ tr, dispatch }) => {
          if (!commentId) return false;

          const commentMarksWithRange: MarkWithRange[] = [];

          tr.doc.descendants((node, pos) => {
            const commentMark = node.marks.find(
              mark => mark.type.name === 'comment' && mark.attrs.commentId === commentId,
            );

            if (!commentMark) return;

            commentMarksWithRange.push({
              mark: commentMark,
              range: {
                from: pos,
                to: pos + node.nodeSize,
              },
            });
          });

          commentMarksWithRange.forEach(({ mark, range }) => {
            tr.removeMark(range.from, range.to, mark);
          });

          return dispatch?.(tr);
        },
    };
  },
});
