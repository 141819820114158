const audioContext = new window.AudioContext(/* || window.webkitAudioContext*/);

// Function to decode audio data
async function decodeAudioData(blob: Blob): Promise<AudioBuffer> {
  const arrayBuffer = await blob.arrayBuffer();
  return await audioContext.decodeAudioData(arrayBuffer);
}

async function generateSilenceBuffer(duration: number) {
  // Calculate the number of frames needed for the duration (duration is in seconds)
  const sampleRate = audioContext.sampleRate;
  const frameCount = sampleRate * duration;

  // Create an empty buffer with the given duration
  const audioBuffer = audioContext.createBuffer(1, frameCount, sampleRate);

  // Fill the buffer with silence (default is 0, so no need to fill explicitly)

  // Create an offline context to render the buffer
  const offlineContext = new OfflineAudioContext(1, frameCount, sampleRate);
  const bufferSource = offlineContext.createBufferSource();
  bufferSource.buffer = audioBuffer;
  bufferSource.connect(offlineContext.destination);
  bufferSource.start();

  // Render the buffer to an audio buffer
  return await offlineContext.startRendering();
}

// Convert AudioBuffer to WAV Blob
function bufferToWavBlob(buffer: AudioBuffer): Blob {
  const numberOfChannels = buffer.numberOfChannels;
  const sampleRate = buffer.sampleRate;
  const format = 1; // PCM

  const bufferLength = buffer.length * numberOfChannels * 2;
  const wavBuffer = new ArrayBuffer(44 + bufferLength);
  const view = new DataView(wavBuffer);

  // Write WAV file header
  writeString(view, 0, 'RIFF');
  view.setUint32(4, 36 + bufferLength, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, format, true);
  view.setUint16(22, numberOfChannels, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, sampleRate * numberOfChannels * 2, true);
  view.setUint16(32, numberOfChannels * 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, 'data');
  view.setUint32(40, bufferLength, true);

  // Write actual samples
  const channels = [];
  for (let i = 0; i < numberOfChannels; i++) {
    channels.push(buffer.getChannelData(i));
  }

  let offset = 44;
  for (let i = 0; i < buffer.length; i++) {
    for (let channel = 0; channel < numberOfChannels; channel++) {
      const sample = Math.max(-1, Math.min(1, channels[channel][i]));
      view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true);
      offset += 2;
    }
  }

  return new Blob([view], { type: 'audio/wav' });
}

export function writeString(view: DataView, offset: number, string: string) {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

export async function generateSilentAudioBlob(index: number, duration: number): Promise<any> {
  const silenceBuffer = await generateSilenceBuffer(duration);
  const wavBlob = await bufferToWavBlob(silenceBuffer);

  return { index, blob: wavBlob };
}

export async function combineWavBlobs(blobs: Blob[]): Promise<Blob> {
  // Decode all blobs
  const audioBuffers = await Promise.all(blobs.map(decodeAudioData));

  // Calculate total length
  const totalLength = audioBuffers.reduce((sum, buffer) => sum + buffer.length, 0);

  // Create a new buffer with the total length
  const combinedBuffer = audioContext.createBuffer(
    audioBuffers[0].numberOfChannels,
    totalLength,
    audioBuffers[0].sampleRate,
  );

  // Copy data from each buffer into the combined buffer
  let offset = 0;
  for (const buffer of audioBuffers) {
    for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
      const channelData = buffer.getChannelData(channel);
      combinedBuffer.copyToChannel(channelData, channel, offset);
    }
    offset += buffer.length;
  }

  return bufferToWavBlob(combinedBuffer);
}

export async function generateIntervalAudioBlob(blob1: Blob, blob2: Blob, silenceDuration: number): Promise<Blob> {
  const [buffer1, buffer2, buffer3] = await Promise.all([
    decodeAudioData(blob1),
    generateSilenceBuffer(silenceDuration),
    decodeAudioData(blob2),
  ]);

  const sampleRate = audioContext.sampleRate;

  // Extract the last second of buffer1
  const lastSecondStartSample = Math.floor((buffer1.duration - 1) * sampleRate);
  const lastSecondLength = Math.floor(sampleRate); // 1 second worth of samples
  const lastSecondBuffer1 = audioContext.createBuffer(buffer1.numberOfChannels, lastSecondLength, sampleRate);
  for (let channel = 0; channel < buffer1.numberOfChannels; channel++) {
    lastSecondBuffer1.copyToChannel(buffer1.getChannelData(channel).subarray(lastSecondStartSample), channel);
  }

  // Extract the first second of buffer3
  const firstSecondLength = Math.floor(1 * sampleRate); // 1 second worth of samples
  const firstSecondBuffer3 = audioContext.createBuffer(buffer3.numberOfChannels, firstSecondLength, sampleRate);
  for (let channel = 0; channel < buffer3.numberOfChannels; channel++) {
    firstSecondBuffer3.copyToChannel(buffer3.getChannelData(channel).subarray(0, firstSecondLength), channel);
  }

  // Calculate total duration
  const totalDuration = 1 + buffer2.duration + 1; // 1 second + buffer2's duration + 1 second

  // Create a new AudioBuffer to hold the combined data
  const numberOfChannels = Math.max(buffer1.numberOfChannels, buffer2.numberOfChannels, buffer3.numberOfChannels);
  const combinedBuffer = audioContext.createBuffer(numberOfChannels, totalDuration * sampleRate, sampleRate);

  // Copy the data to the combined buffer
  for (let channel = 0; channel < numberOfChannels; channel++) {
    const outputData = combinedBuffer.getChannelData(channel);

    // Copy last second of buffer1
    outputData.set(lastSecondBuffer1.getChannelData(channel), 0);

    // Copy all of buffer2
    outputData.set(buffer2.getChannelData(channel), sampleRate);

    // Copy first second of buffer3
    outputData.set(firstSecondBuffer3.getChannelData(channel), Math.floor((1 + buffer2.duration) * sampleRate));
  }

  // Encode the AudioBuffer back to a blob
  const mergedBlob = await bufferToWavBlob(combinedBuffer);

  return mergedBlob;
}
