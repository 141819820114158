import React, { MouseEventHandler } from 'react';
import { FaStepForward, FaStepBackward } from 'react-icons/fa';
import { VscDebugStepBack, VscDebugStepOver } from 'react-icons/vsc';
import { MdPauseCircleFilled, MdPlayCircleFilled } from 'react-icons/md';

const ControlBar = ({
  isPlaying,
  onPlayPause,
  onForward,
  onRewind,
  onNext,
  onPrevious,
}: {
  isPlaying: boolean;
  onPlayPause: MouseEventHandler<HTMLButtonElement>;
  onForward: MouseEventHandler<HTMLButtonElement>;
  onRewind: MouseEventHandler<HTMLButtonElement>;
  onNext: MouseEventHandler<HTMLButtonElement>;
  onPrevious: MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <div className="flex justify-between w-full items-center space-x-4 p-4 rounded-lg">
      <button onClick={onPrevious} className="text-white hover:text-blue-500 transition-colors">
        <FaStepBackward size={18} />
      </button>
      <button onClick={onRewind} className="text-white hover:text-blue-500 transition-colors">
        <VscDebugStepBack size={22} />
      </button>
      <button onClick={onPlayPause} className="text-white hover:text-blue-500 transition-colors">
        {isPlaying ? <MdPauseCircleFilled size={42} /> : <MdPlayCircleFilled size={42} />}
      </button>
      <button onClick={onForward} className="text-white hover:text-blue-500 transition-colors">
        <VscDebugStepOver size={22} />
      </button>
      <button onClick={onNext} className="text-white hover:text-blue-500 transition-colors">
        <FaStepForward size={18} />
      </button>
    </div>
  );
};

export default ControlBar;
