import { create } from 'zustand';
import { v4 as uuidv4 } from 'uuid';
import { generateIntervalAudioBlob } from '../lib/utils';

export interface Voiceline {
  id: string;
  text: string;
  audio: Blob | null;
}

interface VoiceStore {
  voicelines: Voiceline[];
  intervalAudio: Blob | null;
  setIntervalAudio: (one: string, two: string, duration: number) => void;
  addVoice: (text: string, audio: Blob | null, id: string | null) => void;
  removeVoice: (id: string) => void;
  updateVoice: (id: string, newAudio: Blob | null) => void;
  updateVoiceText: (id: string, newText: string) => void;
  resyncVoices: (ids: string[]) => void;
}

const useVoiceStore = create<VoiceStore>((set, get) => ({
  voicelines: [],
  intervalAudio: null,
  setIntervalAudio: async (one, two, duration) => {
    const audioOne = get().voicelines.find(v => v.id === one)?.audio;
    const audioTwo = get().voicelines.find(v => v.id === two)?.audio;

    if (!audioOne || !audioTwo || duration === 0) return;

    const audio = await generateIntervalAudioBlob(audioOne, audioTwo, duration);

    return set(state => ({
      intervalAudio: audio,
    }));
  },
  addVoice: (text, audio, id) =>
    set(state => ({
      voicelines: [...state.voicelines, { id: id || uuidv4(), text, audio }],
    })),
  removeVoice: id =>
    set(state => ({
      voicelines: state.voicelines.filter(voiceline => voiceline.id !== id),
    })),
  updateVoice: (id, newAudio) =>
    set(state => ({
      voicelines: state.voicelines.map(voiceline =>
        voiceline.id === id ? { ...voiceline, audio: newAudio } : voiceline,
      ),
    })),
  updateVoiceText: (id, newText) =>
    set(state => ({
      voicelines: state.voicelines.map(voiceline =>
        voiceline.id === id ? { ...voiceline, text: newText } : voiceline,
      ),
    })),
  resyncVoices: ids =>
    set(state => ({
      voicelines: state.voicelines.filter(voiceline => ids.includes(voiceline.id)),
    })),
}));

export default useVoiceStore;
