import { BubbleMenu, useCurrentEditor } from '@tiptap/react';
import { RiRefreshLine } from 'react-icons/ri';
import useVoiceStore from '../hooks/useVoiceStore';
import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FaHighlighter } from 'react-icons/fa6';
import WaveSurferPlayer from './WavesurferPlayer';

export default function TipTapMenu() {
  const queryClient = useQueryClient();
  const { editor } = useCurrentEditor();
  const [commentId, setCommentId] = useState('');
  const voicelines = useVoiceStore(state => state.voicelines);
  const updateVoiceText = useVoiceStore(state => state.updateVoiceText);
  // const commands = useTextmenuCommands(editor!);
  // const states = useTextmenuStates(editor!);

  const shouldShow = useCallback(() => {
    if (!editor) return false;
    const { state } = editor;
    const { selection } = state;
    const { $from } = selection;

    // Check if the cursor is inside a 'comment' mark
    const commentId = $from.marks().find(mark => mark.type.name === 'comment')?.attrs.commentId;
    // const blob = voicelines.find((v) => v.id === commentId)?.audio;
    // console.log(commentId, voicelines);
    if (commentId) {
      setCommentId(commentId);
      return true;
    }
    return false;
  }, [editor]);

  const onHighlight = () => {
    if (!editor) return false;
    const { state, view } = editor;
    const { selection } = state;
    const { $from } = selection;

    const mark = $from.marks().find(mark => mark.type.name === 'comment');
    if (mark) {
      let markStart = $from.pos;
      let markEnd = $from.pos;

      // Traverse backwards to find the start of the mark
      state.doc.nodesBetween($from.before(), $from.pos, (node, pos) => {
        if (node.marks.some(m => m.type.name === 'comment' && m.attrs.commentId === commentId)) {
          markStart = pos;
        }
      });

      // Traverse forwards to find the end of the mark
      state.doc.nodesBetween($from.pos, $from.after(), (node, pos) => {
        if (node.marks.some(m => m.type.name === 'comment' && m.attrs.commentId === commentId)) {
          markEnd = pos + node.nodeSize;
        }
      });

      // Transaction replacing the old mark
      const tr = state.tr;
      tr.addMark(
        markStart,
        markEnd,
        editor.schema.marks.comment.create({ commentId: mark.attrs.commentId, highlighted: true }),
      );
      console.log(markStart, markEnd);
      view.dispatch(tr);
    }
  };

  const onReload = () => {
    if (!editor) return false;
    const { state } = editor;
    const { selection } = state;
    const { $from } = selection;

    let text = '';
    state.doc.nodesBetween($from.pos, $from.pos, (node, pos) => {
      node.marks.forEach(mark => {
        if (mark.type.name === 'comment') {
          // Extract the text within this mark
          text = node.textContent; // For text nodes
        }
      });
    });
    updateVoiceText(commentId, text);
    // Wait so the text can update
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['voiceline', { id: commentId }] });
    }, 250);
  };

  const audioUrl = useMemo(() => {
    const blob = voicelines.find(v => v.id === commentId)?.audio;
    return blob ? URL.createObjectURL(blob) : null;
  }, [commentId, voicelines]);

  return (
    <BubbleMenu editor={editor} shouldShow={shouldShow}>
      <div className="flex flex-col border border-gray-200 shadow-lg bg-white rounded-md px-2 py-1 min-w-[32rem]">
        <div className="flex items-center justify-between gap-2">
          {/* <span className="cursor-pointer" onClick={commands.onItalic}>italicize</span> */}
          {/* <div
            onClick={() => commands.onVoiceline(uuidv4())}
            className="cursor-pointer flex items-center gap-1 hover:text-gray-500"
          >
            <RiVoiceprintFill className="h-4 w-4" /> Add voiceline
          </div> */}
          <div className="cursor-pointer flex items-center gap-1 hover:text-gray-500" onClick={onReload}>
            <RiRefreshLine className="h-4 w-4" /> Remake
          </div>
          <div className="cursor-pointer flex items-center gap-1 hover:text-gray-500" onClick={onHighlight}>
            <FaHighlighter className="h-4 w-4" />
          </div>
        </div>
        <div>
          {audioUrl && audioUrl.length && <WaveSurferPlayer audioUrl={audioUrl} />}
          {/* <AudioPlayer
            showSkipControls
            src={audioUrl || ''}
            customVolumeControls={[]}
            customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
            className="-top-11 relative scale-[.70]"
          /> */}
        </div>
      </div>
    </BubbleMenu>
  );
}
