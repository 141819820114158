import React from 'react';
import Tiptap from './TipTap';
import VoiceBox from './VoiceBox';
import 'react-h5-audio-player/lib/styles.css';

const AudioForm = () => {
  return (
    <div className="flex flex-col w-full items-center justify-center bg-gray-100 p-4">
      <div className="flex gap-5 relative">
        <Tiptap />
        <VoiceBox />
      </div>
    </div>
  );
};

export default AudioForm;
