export default function Privacy() {
  return (
    <div className="container flex flex-col gap-5 max-w-[42rem] text-white mx-auto pb-10 overflow-y-scroll">
      <h1 className="text-xl font-semibold">Jacket.fm Privacy Policy</h1>
      <p>Last updated: 09/09/2024</p>
      <p>Support contact: hello@jacket.fm</p>
      <div className="section">
        <h2 className="my-2 font-semibold">1. Introduction</h2>
        <p>
          Welcome to Jacket.fm. We respect your privacy and are committed to protecting your personal data. This privacy
          policy will inform you about how we look after your personal data when you use our app and tell you about your
          privacy rights and how the law protects you.
        </p>
      </div>

      <div className="section">
        <h2 className="my-2 font-semibold">2. Data We Collect</h2>
        <p>We may collect, use, store and transfer different kinds of personal data about you:</p>
        <ul className="list-disc list-outside pl-[revert]">
          <li>Identity Data: first name, last name, username</li>
          <li>Contact Data: email address, phone number</li>
          <li>Technical Data: device information, IP address, login data, browser type and version</li>
          <li>Profile Data: your interests, preferences, feedback and survey responses</li>
          <li>Usage Data: information about how you use our app and services</li>
          <li>Content Data: audio content you create, upload, or interact with on our platform</li>
        </ul>
      </div>

      <div className="section">
        <h2 className="my-2 font-semibold">3. How We Use Your Data</h2>
        <p>We use your data to:</p>
        <ul className="list-disc list-outside pl-[revert]">
          <li>Provide and manage your account</li>
          <li>Deliver personalized content and recommendations</li>
          <li>Improve our app and services</li>
          <li>Communicate with you about our services</li>
          <li>Ensure the security and proper functioning of our app</li>
        </ul>
      </div>

      <div className="section">
        <h2 className="my-2 font-semibold">4. Data Sharing and Disclosure</h2>
        <p>We may share your personal data with:</p>
        <ul className="list-disc list-outside pl-[revert]">
          <li>Service providers who provide IT and system administration services</li>
          <li>Professional advisers including lawyers, bankers, auditors and insurers</li>
          <li>Government bodies that require us to report processing activities</li>
        </ul>
        <p>We do not sell your personal data to third parties.</p>
      </div>

      <div className="section">
        <h2 className="my-2 font-semibold">5. Data Security</h2>
        <p>
          We have put in place appropriate security measures to prevent your personal data from being accidentally lost,
          used or accessed in an unauthorized way, altered or disclosed.
        </p>
      </div>

      <div className="section">
        <h2 className="my-2 font-semibold">6. Data Retention</h2>
        <p>
          We will only retain your personal data for as long as necessary to fulfill the purposes we collected it for,
          including for the purposes of satisfying any legal, accounting, or reporting requirements.
        </p>
      </div>

      <div className="section">
        <h2 className="my-2 font-semibold">7. Your Legal Rights</h2>
        <p>
          Under certain circumstances, you have rights under data protection laws in relation to your personal data,
          including the right to:
        </p>
        <ul className="list-disc list-outside pl-[revert]">
          <li>Request access to your personal data</li>
          <li>Request correction of your personal data</li>
          <li>Request erasure of your personal data</li>
          <li>Object to processing of your personal data</li>
          <li>Request restriction of processing your personal data</li>
          <li>Request transfer of your personal data</li>
          <li>Right to withdraw consent</li>
        </ul>
      </div>

      <div className="section">
        <h2 className="my-2 font-semibold">8. Children's Privacy</h2>
        <p>
          Our app is not intended for children under 13 years of age. We do not knowingly collect personal information
          from children under 13.
        </p>
      </div>

      <div className="section">
        <h2 className="my-2 font-semibold">9. Changes to This Privacy Policy</h2>
        <p>
          We may update our privacy policy from time to time. We will notify you of any changes by posting the new
          privacy policy on this page and updating the "Last updated" date.
        </p>
      </div>

      <div className="section">
        <h2 className="my-2 font-semibold">10. Contact Us</h2>
        <p>If you have any questions about this privacy policy, please contact us at:</p>
        <ul className="list-disc list-outside pl-[revert]">
          <li>Email: hello@jacket.fm</li>
        </ul>
      </div>

      <p>By using Jacket.fm, you agree to the collection and use of information in accordance with this policy.</p>
    </div>
  );
}
